import React, { Suspense } from 'react';

//TODO: Change suspense fallback for Loading component
const LazyLoad = ({component: Component, ...rest}) => (
  
  <>
    <Suspense fallback={<span></span>}>
      <Component {...rest} />
    </Suspense>
  </>
);

const Home = React.lazy(() => import('./modules/components/Home'));
const Header = React.lazy(()=>import('./modules/layouts/ResponsiveLayout/Header'))
const Footer = React.lazy(()=>import('./modules/layouts/ResponsiveLayout/Footer'))

const Calendar = React.lazy(() => import('./modules/components/Booking/Calendar'));
const Seating = React.lazy(() => import('./modules/components/Booking/Seating'));
const Passengers = React.lazy(() => import('./modules/components/Booking/Passenger'));
const Secure3D = React.lazy(() => import('./modules/components/Booking/3DSecure'));

const Promotions = React.lazy(() => import('./modules/components/CMS/Promotions'));
const Content = React.lazy(() => import('./modules/components/CMS/Content'));
const GeneralContent = React.lazy(() => import('./modules/components/CMS/GeneralContent'));
const Socios = React.lazy(()=>import('./modules/components/CMS/Socios'));
const Destinations = React.lazy(()=>import('./modules/components/CMS/Destinations/Destination'));

const Reset = React.lazy(()=> import ('./modules/components/CMS/Reset/Reset'));

const Payment = React.lazy(() => import('./modules/components/Booking/Payment/Payment'));
const RappiDone = React.lazy(() => import('./modules/components/Booking/Payment/Rappi/RappiDone'));
//const RappiFail = React.lazy(() => import('./modules/components/Booking/Payment/Rappi/RappiFail'));
const OrkestaCompleted = React.lazy(()=> import('./modules/components/Booking/Payment/Orkesta/OrkestaDone'))
const OrkestaCancel = React.lazy(()=> import('./modules/components/Booking/Payment/Orkesta/OrkestaFail'))

const Reservation = React.lazy(() => import('./modules/components/Booking/Resume/Reservations'));

//const Invoice = React.lazy(()=> import ('./modules/components/Invoice/invoice'));

const Marketplace = React.lazy(()=> import ('./modules/components/Marketplace/marketplace'));

const LazyHome = (props) => <LazyLoad component={Home} {...props}/>
const LazyHeader = (props)=><LazyLoad component={Header} {...props}/>
const LazyFooter = (props)=><LazyLoad component={Footer} {...props}/>

const LazyCalendar = (props) => <LazyLoad component={Calendar} {...props}/>
const LazyPayment = (props) => <LazyLoad component={Payment} {...props}/>
const LazyReservation = (props) => <LazyLoad component={Reservation} {...props}/>
const LazySeating = (props) => <LazyLoad component={Seating} {...props}/>
const LazyPassengers = (props) => <LazyLoad component={Passengers} {...props}/>
const Lazy3DSecure = (props) => <LazyLoad component={Secure3D} {...props}/>
const LazyRappiDone = (props) => <LazyLoad component={RappiDone} {...props}/>
//const LazyRappiFail = (props) => <LazyLoad component={RappiFail} {...props}/>
const LazyOrkestaComplete = (props) => <LazyLoad component={OrkestaCompleted} {...props}/>
const LazyOrkestaCancel = (props) => <LazyLoad component={OrkestaCancel} {...props}/>

const LazyPromotions = (props) => <LazyLoad component={Promotions} {...props}/>
const LazyLegal = (props) => <LazyLoad component={Content} {...props} contentKey="legal" />
const LazyServices = (props) => <LazyLoad component={Content} {...props} contentKey="services" />
const LazyInfo = (props) => <LazyLoad component={Content} {...props} contentKey="info" />
const LazyBaggage = (props) => <LazyLoad component={Content} {...props} contentKey="baggage" />
const LazyAnimalTransport = (props) => <LazyLoad component={Content} {...props} contentKey="animal-transport" />
const LazyFaq = (props) => <LazyLoad component={GeneralContent} {...props} contentId="faq" />
const LazySocio = (props) => <LazyLoad component={Socios} {...props}/>
const LazyReset = (props) => <LazyLoad component={Reset} {...props}/>
//const LazyInvoice = (props) => <LazyLoad component = {Invoice}{...props} />
const LazyMarket = (props) => <LazyLoad component = {Marketplace}{...props} />
const LazyDestinations = (props) => <LazyLoad component={Destinations} {...props} />;

const routes = [
  { path: '/', name: 'home' , component: LazyHome, exact: true},
  { path: '/header', name: 'header' , component: LazyHeader, exact: true},
  { path: '/footer', name: 'footer' , component: LazyFooter, exact: true},
  
  { path: '/calendar', name: 'calendar' , component: LazyCalendar, exact: true},
  { path: '/seating', name: 'seating' , component: LazySeating, exact: true},
  { path: '/passengers', name: 'passengers' , component: LazyPassengers, exact: true},
  { path: '/booking/:idBooking/redirect', name: '3dsecure', component: Lazy3DSecure, exact: false},
  { path: '/payment/:idSession/verify', name: 'rappiDone', component: LazyRappiDone, exact: false},
  //{ path: '/booking/:idSession', name: 'rappiFail', component: LazyRappiFail, exact: false},
  { path: '/booking/:idSession/completed', name: 'orkestaDone', component: LazyOrkestaComplete, exact: false},
  { path: '/booking/:idSession/cancel', name: 'orkestaFail', component: LazyOrkestaCancel, exact: false},

  { path: '/promos', name: 'promos' , component: LazyPromotions, exact: false},
  { path: '/legal', name: 'legal' , component: LazyLegal, exact: false},
  { path: '/services', name: 'services' , component: LazyServices, exact: false},
  { path: '/info', name: 'info' , component: LazyInfo, exact: false},
  { path: '/baggage', name: 'baggage' , component: LazyBaggage, exact: false},
  { path: '/animal-transport', name: 'animal-transport' , component: LazyAnimalTransport, exact: false},
  { path: '/faq', name: 'faq' , component: LazyFaq, exact: false},
  { path: '/payment', name: 'payment' , component: LazyPayment, exact: false},
  { path: '/reservation', name: 'reservation' , component: LazyReservation, exact: false},
  { path: '/reset', name: 'reset' , component: LazyReset, exact: false},
  //{ path: '/invoice', name: 'invoice' , component: LazyInvoice, exact: false},
  { path: '/marketplace', name: 'Marketplace' , component: LazyMarket, exact: false},
  { path: '/destination', name: 'destination' , component: LazyDestinations, exact: false},
  { path: '/socios', name: 'socios' , component: LazySocio, exact: false},
];

export default routes;
