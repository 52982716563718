import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../assets/style/scss/state-machine.scss';
import { injectIntl } from 'react-intl';
import Footer from './Footer';
import LoadingModal from '../../components/Commons/LoadingModal';

function LayoutFooter() {
    return (
        <Grid container className="app">
            <Grid className="footer">
                <Container disableGutters maxWidth={false}>
                    <Suspense fallback={<CircularProgress />}>
                        <Footer />
                    </Suspense>
                </Container>
            </Grid>
        </Grid>
    )
}



export default injectIntl(Layout);