import React, {Component} from 'react';
import {injectIntl } from 'react-intl';
import { Menu, List, ListItem } from '@mui/material';


class MobileMenu extends Component {

    render() {
        return (
            <Menu anchorEl={this.props.anchorEl} keepMounted open={Boolean(this.props.anchorEl)} onClose={this.props.onClose}>
                <List component="nav">
                    <ListItem button divider onClick={this.props.closeMenu}>
                        Profile
                    </ListItem>
                    <ListItem button divider onClick={this.props.closeMenu}>
                        My account
                    </ListItem>
                    <ListItem button onClick={this.props.closeMenu}>
                        Logout
                    </ListItem>
                </List>
            </Menu>
        );
    }
}

export default injectIntl(MobileMenu);