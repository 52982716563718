import React, { Component, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import { Button, Hidden } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

import LanguageSelector from './LanguageSelector/LanguageSelector';
import MobileMenu from './MobileMenu';
import { ENVIRONMENT, ENVIRONMENTS } from '../../../env';
import { useEffect } from 'react';
import RequestManager from '../../utils/RequestManager';

function Header(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [cintillo, setCintillo] = useState(false);
	const [buttonsHd, setButtonsHd] = useState([]);

	useEffect(() => {
		const handleData = async () => {
			const request = {
				serviceName: 'dashboardMenu',
			};

			const dataMenu = await RequestManager.callAsyncCmsService(request);

			if (Array.isArray(dataMenu)) setButtonsHd(dataMenu);
		};

		handleData();
	}, []);

	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const onCloseMenu = () => {};

	const location = window.location.href.split('#')[1].split('?')[0];

	return (
		<Grid container spacing={0}>
			{(ENVIRONMENT === ENVIRONMENTS.DEV || ENVIRONMENT === ENVIRONMENTS.SAND) && (
				<Grid container className='dev-alert' justifyContent='center'>
					<Grid item>
						<FormattedMessage id='app.header.dev.alert' />
						<a href='https://tarmexico.com'>
							<FormattedMessage id='app.header.dev.alert.click' />
						</a>
					</Grid>
				</Grid>
			)}
			{cintillo && (
				<Grid container className='prod-alert' justify-content='center'>
					<Grid item>
						<marquee scrollamount='8'>
							<FormattedMessage id='app.header.prod.alert' />
						</marquee>
					</Grid>
				</Grid>
			)}
			{/*<Hidden only={"xs"}>
                <Grid container className="assistance-bar" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <FormattedMessage id="app.header.call.center.label" />
                        <a href={"tel:" + props.intl.formatMessage({ id: "app.header.call.center.number" })}>
                            <span className="color-white"><FormattedMessage id="app.header.call.center.number" /></span>
                        </a>
                    </Grid>*/}
			{/* <Grid item className="separator">
                            |
                        </Grid>
                        <Grid item>
                            <TarLogo className="tar-logo"/>
                            <span className="color-white"><FormattedMessage id="app.header.travel.agency" /></span>
                        </Grid> 
                </Grid>
            </Hidden>*/}
			<Hidden only={['xl', 'lg', 'md', 'sm']}>
				<Grid container className='menu-bar-mobile' alignItems='center' justifyContent='space-around'>
					<Grid item>
						<a href='/'>
							<img src='/img/logo_Tar.svg' alt='TAR' className='logo' />
						</a>
					</Grid>
					{/*<Grid item>
                        <Grid container>
                            <Grid item>
                                <PhoneIcon />
                            </Grid>
                            <Grid item className="title">
                                <FormattedMessage id="app.header.call.center.number" />
                            </Grid>
                        </Grid>
                    </Grid>*/}
					<Grid item className='movile_lang'>
						<LanguageSelector />
					</Grid>
					<Grid className='menuBtn'>
						{buttonsHd.map((item, index) => (
							<Button className='ButtonHd' variant='text' color='secondary' href={item.url} key={index} target='_blank'>
								{item.title}
							</Button>
						))}
					</Grid>
					{/* <Grid item className="menu-icon">
                            <MenuIcon fontSize="inherit" onClick={this.openMenu}/>
                        </Grid> */}
				</Grid>
			</Hidden>
			<Hidden only='xs'>
				<Grid container className='menu-bar' alignItems='center' justifyContent='flex-end'>
					<Grid item className='logo-container'>
						<a href='/'>
							<img src='/img/logo_Tar.svg' alt='TAR' className='logo' />
						</a>
					</Grid>
					<Grid className='menuBtn'>
						{buttonsHd.map((item, index) => (
							<Button className='ButtonHd' variant='text' color='secondary' href={item.url} key={index} target='_blank'>
								{item.title}
							</Button>
						))}
					</Grid>
					<Grid item>
						<LanguageSelector />
					</Grid>
				</Grid>
			</Hidden>
			<MobileMenu anchorEl={anchorEl} closeMenu={closeMenu} onClose={onCloseMenu} />
		</Grid>
	);
}

export default injectIntl(Header);
