import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from '@mui/material';
import RequestManager from '../../../utils/RequestManager';
import countries from '../../../utils/countries';
import SelectorL from './SelectorL';

function LanguageSelector(props) {
    
    const [languages, setLanguages] = useState([]);
    const [language, setLanguage] = useState("");
    const [country, setCountry] = useState("");
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let request = {
            serviceName: 'langs',
        };
        RequestManager.callIbeService(request, (data) => {
            parseAvailableLanguages(data);
        }, (error) => {
            console.log(new Error("Error to load langs"));
            console.log(error);
        });
    }, [])

    const parseAvailableLanguages = (langs) => {
        let lang = localStorage.getItem("language");
        let country = localStorage.getItem("country");
        if (!lang) {
            lang = countries.MX.language;
            country = countries.MX.code;
            localStorage.setItem("language", lang);
        }
        setLanguages(languages => languages.concat(langs.reverse()));
        setLanguage(lang)
        setCountry(country)
        setLoaded(true)
    }

    return (
        <Grid container className='selectorLan'>
            <FormattedMessage id="app.header.language.label" />
            <span>&nbsp;&nbsp;</span>
            <Grid className='selectorLang' value={loaded ?  country + language : ''}>
                <SelectorL languages={languages} lang={language} country={country} />
            </Grid>
        </Grid >
    );

}

export default injectIntl(LanguageSelector);