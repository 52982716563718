import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Hidden } from '@mui/material';
import { ReactComponent as TwitterIcon } from '../../../assets/img/Twitter.min.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/img/Insta.min.svg';
import { ReactComponent as YouTubeIcon } from '../../../assets/img/YT.min.svg';
import RequestManager from '../../utils/RequestManager';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PhoneInTalk } from '@mui/icons-material';
import { ReactComponent as Facebook } from '../../../assets/img/facebook.min.svg';
import { ReactComponent as Linkedin } from '../../../assets/img/in.min.svg';

const LEGAL_ENDPOINT = 'legal';
const INFO_ENDPOINT = 'info';
const SERVICES_ENDPOINT = 'services';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			legal: {
				title: '',
				items: [],
			},
			info: {
				title: '',
				items: [],
			},
			services: {
				title: '',
				items: [],
			},
			redirect: false,
			uri: '',
		};
	}

	componentDidMount() {
		this.loadMenu('legal', LEGAL_ENDPOINT);
		this.loadMenu('info', INFO_ENDPOINT);
		this.loadMenu('services', SERVICES_ENDPOINT);
	}

	loadMenu(storage, id) {
		let request = {
			serviceName: 'menu',
			urlParams: {
				id,
			},
		};
		RequestManager.callCmsService(
			request,
			(dataResponse) => {
				if (dataResponse && dataResponse.items) {
					dataResponse.items.forEach((item) => {
						if (item.url === '') {
							item.url = window.location.protocol + '//' + window.location.host + '/#/' + item.contentId;
						} else if (item.url.startsWith('http')) {
							item.newTab = true;
						} else {
							item.url = window.location.protocol + '//' + window.location.host + '/#' + item.url;
						}
					});
					this.setState({
						[storage]: dataResponse,
					});
				}
			},
			(error) => {
				console.debug(error);
			}
		);
	}

	onClickUrl = (evt) => {
		evt.preventDefault();
		let uri = evt.target.href.split('#')[1];
		this.setState(
			{
				redirect: true,
				uri,
			},
			() => {
				window.location.reload();
			}
		);
	};

	render() {
		const currentYear = new Date().getFullYear();
		return (
			<Grid container className='footer'>
				{this.state.redirect && <Redirect to={this.state.uri} push={true} />}
				<Grid container spacing={1} className='links'>
					<Grid item sm={6} md={4} lg={3}>
						<div className='title'>{this.state.legal.title}</div>
						<ul>
							{this.state.legal.items.map((item, index) => {
								if (item.newTab) {
									return (
										<li key={index}>
											<a href={item.url} rel='noopener noreferrer'>
												{item.title}
											</a>
										</li>
									);
								}
								return (
									<li key={index}>
										<a href={item.url} onClick={this.onClickUrl}>
											{item.title}
										</a>
									</li>
								);
							})}
						</ul>
					</Grid>
					<Grid item sm={6} md={4} lg={3}>
						<div className='title'>{this.state.info.title}</div>
						<ul>
							{this.state.info.items.map((item, index) => {
								if (item.newTab) {
									return (
										<li key={index}>
											<a href={item.url} rel='noopener noreferrer'>
												{item.title}
											</a>
										</li>
									);
								}
								return (
									<li key={index}>
										<a href={item.url} onClick={this.onClickUrl}>
											{item.title}
										</a>
									</li>
								);
							})}
						</ul>
					</Grid>
					<Grid item sm={6} md={4} lg={3}>
						<div className='title'>{this.state.services.title}</div>
						<ul>
							{this.state.services.items.map((item, index) => {
								if (item.newTab) {
									return (
										<li key={index}>
											<a href={item.url} rel='noopener noreferrer'>
												{item.title}
											</a>
										</li>
									);
								}
								return (
									<li key={index}>
										<a href={item.url} onClick={this.onClickUrl}>
											{item.title}
										</a>
									</li>
								);
							})}
						</ul>
					</Grid>
					<Grid item sm={6} md={4} lg={3}>
						<div className='title'>
							<FormattedMessage id='app.footer.links.contact' />
						</div>
						<ul>
							<li>
								<a href='mailto:comentarios@tarmexico.com' target='_blank' rel='noopener noreferrer'>
									<FormattedMessage id='app.footer.links.contact.comments' />
								</a>
							</li>
							<li>
								<a href='mailto:rhtar@tarmexico.com' target='_blank' rel='noopener noreferrer'>
									<FormattedMessage id='app.footer.links.contact.job' />
								</a>
							</li>
							<li>
								<a href='/#/faq' target='_blank' rel='noopener noreferrer'>
									<FormattedMessage id='app.footer.links.contact.faq' />
								</a>
							</li>
						</ul>

						<Grid item className='socialM'>
							<Grid className='vuela'>
								<FormattedMessage
									id='app.footer.links.social'
									defaultMessage='app.footer.links.social'
									values={{
										strong: (chunks) => <strong>{chunks}</strong>,
									}}
								/>
							</Grid>
							<div className='icons'>
								<a
									href='https://www.facebook.com/TARAerolineas?mibextid=ZbWKwL'
									target='_blank'
									rel='noopener noreferrer'
								>
									<img src={require('../../../assets/img/facebook.min.svg').default} />
								</a>
								<a href='https://twitter.com/vuelaTAR' target='_blank' rel='noopener noreferrer'>
									<img src={require('../../../assets/img/Twitter.min.svg').default} />
								</a>
								<a href='https://www.instagram.com/vuelatar/' target='_blank' rel='noopener noreferrer'>
									<img src={require('../../../assets/img/Insta.min.svg').default} />
								</a>
								<a
									href='https://www.youtube.com/channel/UCBZKf0ClsXlGlQkZ7PAQqjg'
									target='_blank'
									rel='noopener noreferrer'
								>
									<img src={require('../../../assets/img/YT.min.svg').default} />
								</a>
								<a href='https://www.linkedin.com/company/vuelatar/' target='_blank' rel='noopener noreferrer'>
									<img src={require('../../../assets/img/in.min.svg').default} />
								</a>
							</div>
						</Grid>

						<Grid item className='callCenter'>
							<Grid className='phoneCc'>
								<PhoneInTalk />
							</Grid>
							<Grid>
								<FormattedMessage id='app.header.call.center.label' />
								<a href={'tel:' + this.props.intl.formatMessage({ id: 'app.header.call.center.number' })}>
									<span className='color-white'>
										<FormattedMessage id='app.header.call.center.number' />
									</span>
								</a>
								<a href='mailto:atencionaclientes@tarmexico.com' target='_blank' rel='noopener noreferrer'>
									<FormattedMessage id='app.footer.email' />
								</a>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container className='alliances'>
					<Grid item className='title'>
						<FormattedMessage id='app.footer.business' />
					</Grid>
					<Grid container alignItems='center' justifyContent='space-between' mt={2}>
						<Grid item width='10em' height='4em' alignContent='center'>
							<a href='http://lasgardenias.com.mx/' target='_blank' rel='noopener noreferrer'>
								<img src='/img/partner_gardenias.png' alt='Las Gardenias, residencial para adultos mayores' />
							</a>
						</Grid>
						<Grid item width='10em' height='4em' alignContent='center'>
							<a href='https://vuelos.tarmexico.com/revista' target='_blank' rel='noopener noreferrer'>
								<img src='/img/LogoRevistaATMOSFERA_footer_gris.png' alt='StarNews' />
							</a>
						</Grid>
						<Grid item width='10em' height='4em' alignContent='center' p={2}>
							<a href='https://tcpip.tech' target='_blank' rel='noopener noreferrer'>
								<img src='/img/sponsors/logo_TCPIP.svg' alt='TCPIP' />
							</a>
						</Grid>
						<Grid item width='10em' height='4em' alignContent='center'>
							<a href='http://www.openpay.mx/' target='_blank' rel='noopener noreferrer'>
								<img src='/img/partner_openpay.svg' alt='Openpay' />
							</a>
						</Grid>
						<Grid item width='10em' height='4em' alignContent='center'>
							<a href='https://orkestapay.com/' target='_blank' rel='noopener noreferrer'>
								<img src='/img/sponsors/logo_OrkestaPay.svg' alt='Orkesta Pay' />
							</a>
						</Grid>
						<Grid item width='10em' height='4em' alignContent='center'>
							<a href='http://www.solfium.com' target='_blank' rel='noopener noreferrer'>
								<img src='/img/LogoSolfiumGrayscale.png' alt='Solfium' />
							</a>
						</Grid>
					</Grid>
					<Grid container alignItems='center' justifyContent='center' className='copy-right'>
						&copy; {currentYear} <FormattedMessage id='app.footer.copy.right' />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default injectIntl(Footer);
