import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Select, MenuItem } from '@mui/material';

function SelectorL({ lang, languages }) {

    const handleChange = (event) => {
        console.log(event)
        console.log(languages)
        let values = event.target.value;
        localStorage.setItem("language", values.slice(2, 4));
        localStorage.setItem("country", values.slice(0, -2));
        window.location.reload();
    }

    const flags = languages.map((lang) => {
        return <MenuItem
            key={lang.shortName}
            value={lang.country + lang.shortName}>
            <img className='flag-Lang' src={lang.icon} />
            <span className="btnLang"><FormattedMessage id={lang.longName} /></span>
        </MenuItem>
    })

    return (
        <Grid item className="selectorLang">
            {languages.map((len) => {
                if (lang == len.shortName) {
                    return <Select variant='outlined'
                        className="btnLang"
                        sx={{ '& > fieldset': { border: 'none' } }}
                        key={len.shortName}
                        value={len.country + len.shortName}
                        onChange={handleChange}
                        options={languages}>
                        {flags}
                    </Select>
                }
            })}

        </Grid>

    );
}

export default injectIntl(SelectorL);